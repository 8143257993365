/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unescaped-entities */
import { useState, useRef } from 'react'

import styled from 'styled-components'
import { Box } from 'widgets'
import Features from './components/Features/Features'
import HeroBanner from './components/HeroBanner'
import Introduce from './components/Introduce'
import Vision from './components/Vision'

const Wrapper = styled.div`
  background-color: #fff;

  .hidden {
    overflow: hidden;
    height: 100vh;

    &.disable-scroll {
      margin-top: 0;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 150;
    }
  }
`

const HomePage = ({ setLoading }) => {
  const introduceRef = useRef(null)
  const [isBlockContent, setIsBlockContent] = useState(false)

  const scrollToElement = () => {
    setIsBlockContent(true)
    setTimeout(() => {
      const targetPosition = introduceRef.current.offsetTop
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth',
      })
    }, 100)
  }

  return (
    <Wrapper>
      <Box className={`${!isBlockContent && 'disable-scroll'} hidden`}>
        <HeroBanner scrollToElement={scrollToElement} />
      </Box>
      <Box style={{ display: isBlockContent ? 'block' : 'none' }}>
        <div ref={introduceRef}>
          <Introduce />
        </div>
        <Features />

        <Vision />

        {/* <News setLoading={setLoading} /> */}
      </Box>
    </Wrapper>
  )
}

export default HomePage
